import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

const TourItem = ({ title, description, className, link }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  return (
    <div className="tours__item" onClick={() => navigate(link)}>
      <div className={`tours__picture ${className}`} />
      <div className="tours__title">{t(`${[title]}`)}</div>
      <div className="tours__description">{t(`${[description]}`)}</div>
      <Link to={link}>
        <Button
          variant="contained"
          className="tours__button"
          style={{ backgroundColor: '#283033' }}>
          {t('more')}
        </Button>
      </Link>
    </div>
  );
};

export default TourItem;
