import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import './App.scss';
import Tours from './pages/Tours';
import Footer from './pages/Home/components/Footer';
import Tour from './pages/Tour';
import Rent from './pages/Rent';
import ScrollToTop from './components/ScrollToTop';
import ServicePage from './pages/ServicePage';
import Tour66 from './pages/Tour66';

const App = () => {
  return (
    <div className="app">
      <Router>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tours" element={<Tours />} />
          <Route path="/service" element={<ServicePage />} />
          <Route path="/rent" element={<Rent />} />
          <Route path="/tour66" element={<Tour66 />} />
          <Route path="tours/:type" element={<Tour />} />
        </Routes>
        <a href="https://wa.me/37477093030" target="blank">
          <div className="app__icon-wrapper">
            <div className="app__icon" />
          </div>
        </a>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
