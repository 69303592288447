export const slideData = [
  { id: 'img1', img: 'https://i.ibb.co/JF0J1MY/1.jpg' },
  { id: 'img2', img: 'https://i.ibb.co/kKG99h1/2.jpg' },
  { id: 'img3', img: 'https://i.ibb.co/mJcWxJw/3.jpg' },
];

export const motos = [
  {
    img: 'rent__img7 ',
    block: 'block7',
    model: 'Yamaha XT660R',
    weight: 'yamahaWeight',
    type: 'dualType',
    engine: 'engineCylinder1',
    stroke: 4,
    volume: '660.00 cm³',
    finalDrive: 'chain',
    transmission: 'speed5',
    power: '48',
    cooling: 'liquid',
  },
  {
    img: 'rent__img3',
    block: 'block3',
    model: 'Suzuki DR650SE',
    weight: 'suzukiWeight',
    type: 'dualType',
    engine: 'engineCylinder1',
    stroke: 4,
    volume: '644.00 cm³',
    finalDrive: 'chain',
    transmission: 'speed5',
    power: '44',
    cooling: 'airOil',
  },
  {
    img: 'rent__img5',
    block: 'block5',
    model: 'Kawasaki KLE 500',
    weight: 'kawasakiWeight',
    type: 'dualType',
    engine: 'engineCylinder1',
    stroke: 4,
    volume: '498 cm³',
    finalDrive: 'chain',
    transmission: 'speed5',
    power: '44.8',
    cooling: 'liquid',
  },
  {
    img: 'rent__img6',
    block: 'block6',
    model: 'Kawasaki KLR650',
    weight: 'kawasaki2Weight',
    type: 'dualType',
    engine: 'engineCylinder1',
    stroke: 4,
    volume: '651.00 cm³',
    finalDrive: 'chain',
    transmission: 'speed5',
    power: '48',
    cooling: 'liquid',
  },
  {
    img: 'rent__img2',
    block: 'block2',
    model: 'BMW F650GS',
    weight: 'bmw2weight',
    type: 'dualType',
    engine: 'engineCylinder1',
    stroke: 4,
    volume: '652 cm³',
    finalDrive: 'chain',
    transmission: 'speed5',
    power: '50',
    cooling: 'liquid',
  },
  {
    img: 'rent__img4',
    block: 'block4',
    model: 'Honda Africa Twin',
    weight: 'hondaWeight',
    type: 'dualType',
    engine: 'vTwinEngine',
    stroke: 4,
    volume: '742 cm³',
    finalDrive: 'chain',
    transmission: 'speed5',
    power: '62',
    cooling: 'liquid',
  },
  {
    img: 'rent__img1',
    block: 'block1',
    model: 'BMW  R1200GS',
    weight: 'bmw1weight',
    type: 'dualType',
    engine: 'bmw1engine',
    stroke: 4,
    volume: '1,170 cm³',
    finalDrive: 'cardan',
    transmission: 'speed5',
    power: '123',
    cooling: 'airLiquid',
  },
];
