import { useTranslation } from 'react-i18next';
import { Link, Element, scroller } from 'react-scroll';
import Slideshow from '../../components/Slideshow';
import { slideData, motos } from './RentData';
import { Button } from '@mui/material';
import TaskIcon from '@mui/icons-material/Task';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import './Rent.scss';

const Rent = () => {
  const { t } = useTranslation();

  const scrollToBlock = (blockName) => {
    scroller.scrollTo(blockName, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <div className="rent">
      <Slideshow data={slideData} />
      <div className="rent__motos-wrapper">
        {motos.map((item) => (
          <Link to={item.block} smooth={true} duration={800}>
            <div
              className={`rent__moto-item ${item.img}`}
              onClick={() => scrollToBlock('block1')}
            />
          </Link>
        ))}
      </div>
      <div>
        {motos.map((item) => (
          <Element name={item.block}>
            <div className="rent__detailed-wrapper">
              <div className="rent__detailed">
                <div className="rent__title">
                  <div className="rent__model">{item.model}</div>
                  <div className={`rent__detailed-item ${item.img}`} />
                </div>
                <div className="rent__description">
                  <div className="rent__values">
                    <div className="rent__key">{t('weight')}:</div>
                    <div>{t(`${item.weight}`)}</div>
                  </div>
                  <div className="rent__values">
                    <div className="rent__key">{t('type')}:</div>
                    <div>{t(`${item.type}`)}</div>
                  </div>
                  <div className="rent__values">
                    <div className="rent__key">{t('engine')}:</div>
                    <div>{t(`${item.engine}`)}</div>
                  </div>
                  <div className="rent__values">
                    <div className="rent__key">{t('stroke')}:</div>
                    <div>{item.stroke}</div>
                  </div>
                  <div className="rent__values">
                    <div className="rent__key">{t('volume')}:</div>
                    <div>{item.volume}</div>
                  </div>
                  <div className="rent__values">
                    <div className="rent__key">{t('finalDrive')}:</div>
                    <div>{t(`${item.finalDrive}`)}</div>
                  </div>
                  <div className="rent__values">
                    <div className="rent__key">{t('transmission')}:</div>
                    <div>{t(`${item.transmission}`)}</div>
                  </div>
                  <div className="rent__values">
                    <div className="rent__key">{t('power')}:</div>
                    <div>{item.power}: </div>
                    <div>{t('hp')}</div>
                  </div>
                  <div className="rent__values">
                    <div className="rent__key">{t('cooling')}:</div>
                    <div>{t(`${item.cooling}`)}</div>
                  </div>
                  <div className="rent__contact">
                    <a className="footer__label" href={'mailto:dreamriders.armenia@gmail.com'}>
                      <Button
                        style={{
                          backgroundColor: '#283033',
                        }}
                        variant="contained">
                        {t('contactUs')}
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Element>
        ))}
      </div>
      <div className="rent__general">
        <div className="rent__general-title">{t('terms')}</div>
        <div className="rent__general-blocks">
          <div className="rent__general-block">
            <TaskIcon fontSize="large" className="rent__icon" />
            <div className="rent__texts">
              <p>{t('term1')}</p>
              <p>{t('term2')}</p>
              <p>{t('term3')}</p>
              <p>{t('term4')}</p>
              <p>{t('term5')}</p>
            </div>
          </div>
          <div className="rent__general-block">
            <BeenhereIcon fontSize="large" className="rent__icon" />
            <div className="rent__texts">
              <p>{t('requirement1')}</p>
              <p>{t('requirement2')}</p>
              <p>{t('requirement3')}</p>
              <p>{t('requirement4')}</p>
            </div>
          </div>
          <div className="rent__general-block">
            <AttachMoneyIcon fontSize="large" className="rent__icon" />
            <div className="rent__texts">
              <p>{t('price1')}</p>
              <p>{t('price2')}</p>
              <p>{t('price3')}</p>
              <p>{t('price4')}</p>
              <p>{t('price5')}</p>
              <p>{t('price6')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rent;
