import { Button } from '@mui/material';
import Slideshow from '../../components/Slideshow';
import './ServicePage.scss';
import { useTranslation } from 'react-i18next';

const slideData = [
  { id: 'img1', img: 'https://i.ibb.co/8b5Ln94/1.jpg' },
  { id: 'img2', img: 'https://i.ibb.co/YtHpCWk/2.jpg' },
  { id: 'img3', img: 'https://i.ibb.co/6XjK0Mg/3.jpg' },
];
const ServicePage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Slideshow data={slideData} />
      <div className="service-page__detailed-wrapper">
        <div className="service-page__detailed">
          <div className="service-page__title">
            <div className="service-page__model">{t('serviceLabel')}</div>
            <div className="service-page__img-wrapper">
              <img
                className="service-page__img"
                loading="lazy"
                src="https://i.ibb.co/qFD7s4R/1.jpg"
                alt="1"
              />
              <div className="service-page__text-wrapper">
                <div className="service-page__text">{t('serviceText')}</div>
                <a className="service-page__label" href={'mailto:dreamriders.armenia@gmail.com'}>
                  <Button
                    style={{
                      backgroundColor: '#283033',
                    }}
                    variant="contained">
                    {t('contactUs')}
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <div className="service-page__description"></div>
        </div>
        <div className="service-page__detailed">
          <div className="service-page__title">
            <div className="service-page__model">{t('storage')}</div>
            <div className="service-page__img-wrapper">
              <img
                className="service-page__img"
                loading="lazy"
                src="https://i.ibb.co/R2WhrSk/2.jpg"
                alt="1"
              />
              <div className="service-page__text-wrapper">
                <div className="service-page__text">{t('storageText')}</div>
                <a href={'mailto:dreamriders.armenia@gmail.com'}>
                  <Button
                    style={{
                      backgroundColor: '#283033',
                    }}
                    variant="contained">
                    {t('contactUs')}
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <div className="service-page__description"></div>
        </div>
      </div>
    </div>
  );
};

export default ServicePage;
