import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Gallery from './components/Gallery';
import ToursData from './ToursData';
import './Tour.scss';
import { Button } from '@mui/material';

const Tour = () => {
  const { type } = useParams();
  const { t } = useTranslation();
  const currentTour = ToursData[type];

  return (
    <>
      <div className="tour__header" />
      <div className="tour">
        <div className="tours__wrapper">
          <div className="tour__title1">{t([currentTour.title])}</div>
          <div className="tour__gallery-wrapper">
            <div className="tour__info">
              <div className="tour__title">{t([currentTour.title])}</div>
              <div className="tour__desc">{t([currentTour.description])}</div>
              <div className="tour__desc-wrapper">
                {currentTour.price && (
                  <div className="tour__desc">
                    {t('price')}: {currentTour.price} {t('currency')}
                  </div>
                )}
                {currentTour.pricePerpass && (
                  <div className="tour__desc">
                    {t('pricePerpass')}: {currentTour.pricePerpass} {t('currency')}
                  </div>
                )}
                {currentTour.escortPrice && (
                  <div className="tour__desc">
                    {t('escortPrice')}: {currentTour.escortPrice} {t('currency')}
                  </div>
                )}
              </div>
              <div className="tour__desc">{t([currentTour.priceIncludes])}</div>
              {currentTour.note && (
                <div className="tour__desc-wrapper">
                  <div className="tour__desc">
                    {t('note')}: {t([currentTour.note])}
                  </div>
                </div>
              )}
              <div className="tour__about">
                {currentTour.days.map((item, index) => {
                  return (
                    <div className="tour__day-wrapper">
                      <span className="tour__day-number">{`${t('day')} ${index + 1}.`}</span>
                      <div className="tour__day-info">{t([item])}</div>
                    </div>
                  );
                })}
              </div>
              <a className="footer__label" href={'mailto:dreamriders.armenia@gmail.com'}>
                <Button
                  style={{
                    backgroundColor: '#283033',
                  }}
                  variant="contained">
                  {t('contactUs')}
                </Button>
              </a>
            </div>
            <div className="tour__slide">
              <Gallery images={currentTour.images} />
              <div className="tour__map-wrapper">
                <img src={currentTour.map} alt="map" loading="lazy" className="tour__map" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tour;
